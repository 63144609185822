import React from "react";
import {
  Typography,
  Box,
  makeStyles,
  Button,
  TextField,
} from "@material-ui/core";
import { BiTimeFive } from "react-icons/bi";
 
import moment from 'moment';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  NftImg: {
    borderRadius: 11,
    
    display: "block",
    position: "relative",
    "& figure": {
      position: "relative",
      overflow: "hidden",
      height: "185px",
      maxWidth: "100%",
      borderRadius: 11,
      "& img": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: 11,
        // maxHeight: "100%",
        width: "100%",
        boxShadow: "0 11px 24px rgb(0, 0, 0, 0.12)",
      },
    },
  },
  bottomblock: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  bottomTop: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 0",
  },
  textField: {
    marginRight: "10px",
    height: "35px",
  },
}));

export default function ProfileCard(props) {
  const { type, data,rejectBid } = props;
  const classes = useStyles();
const history = useHistory();
  return (
    <Box className="bgCardcolor" style={{ borderRadius: 1 }}>
      <Box display="flex width100">
        <Box className="collectionSet" style={{ padding: "17px" }}>
          <Box className="bgcard">
            {/* <Box className={classes.NftImg}>
              <Link to="/nft-details">
                <figure>
                  <img
                    src={data.nftId.uri}
                  alt="" />
                </figure>
              </Link>
            </Box> */}
            <Box className={classes.NftImg}>
              <img
                src={data.nftId.uri}
                width="100%"
                alt=""
                style={{ borderRadius: '5px', minHeight: 250 , height:"180px"}}
              />
            </Box>
            {/* {type === "hasOffer" ? (
              <Box className="Ticker">
                <Typography
                  variant="h5"
                  className="NftPrice"
                  style={{ marginBottom: "5px", padding: "0 10px" }}
                >
                  Offer Amount: <span> {data.price}</span>
                </Typography>
              </Box>
            ) : (
              <Box className="Ticker">
                <Typography
                  variant="h5"
                  className="NftPrice"
                  style={{ marginBottom: "5px" }}
                >
                  Current Bid
                </Typography>
              </Box>
            )} */}
            <Box mt={2} className="detailsBox">
              <Box pt={0}>
                <Box>
                  <Typography
                    variant="h6"
                    className="NftHead"
                    style={{ marginBottom: "5px" }}
                  >
                    {data.nftId.tokenName}
                  </Typography>
                </Box>

                {type === "auction" || type === "auctionLowBid" ? (
                  <Box mb={1} mt={1} className={classes.bottomblock}>
                    <Typography
                      variant="h5"
                      className="NftExpire"
                      style={{ marginBottom: "5px" }}
                    >
                      <BiTimeFive
                        style={{ fontSize: "13px", marginRight: "5px" }}
                      />
                      Closing in  
                      {moment(data.expiryTime).format('DD MMM YYYY hh:mm A')}
                    </Typography>
                    {/* <Typography
                      variant="h5"
                      className="NftReserved"
                      style={{ marginBottom: "5px" }}
                    >
                      Reserve Price not met
                    </Typography> */}
                  </Box>
                ) : (
                  <Box mb={1} className={classes.bottomblock}>
                    <Typography
                      variant="h5"
                      className="NftExpire"
                      style={{ marginBottom: "5px" }}
                    >
                      <BiTimeFive
                        style={{ fontSize: "13px", marginRight: "5px" }}
                      />
                      Closing on
                      {moment(data.expiryTime).format('DD YYY YYYY hh:mm A')}
                    </Typography>
                  </Box>
                )}
                {type === "auction" ? null
                  :// <Box className={classes.bottomblock}>
                  //   <TextField
                  //     type="text"
                  //     variant="outlined"
                  //     fullWidth
                  //     label="Enter BNB Amount"
                  //     className={classes.textField}
                  //   />
                  //   <Button variant="contained" color="primary" size="small">
                  //     Place Bid
                  //   </Button>
                  // </Box>
                
                  ""
               }
                {type === "auctionLowBid" ? (
                  <Box>
                    <Typography
                      variant="h6"
                      className="btn-custom1"
                      style={{ marginBottom: "5px" , display:"flex", justifyContent:"center",borderTop:"1px solid white"}}
                    ><Button
                    onClick={() =>
                      history.push({
                        pathname: '/nft-details',
                        search: data._id,
                      })
                    }
                    style={{ marginTop: "20px"}}
                    variant="contained"
                    color="success"
                    size="small"
                  >
                    View Details
                  </Button>
                     
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}
                {type === "hasOffer" ? (
                  <Box className={classes.bottomblock}>
                    {/* <Button variant="contained" color="primary" size="small">
                      Accept
                    </Button> */}
                    <Button
                      onClick={() => rejectBid(data)}
                      variant="contained"
                      color="success"
                      size="small"
                    >
                      Reject
                    </Button>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
