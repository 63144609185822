// let url;
let baseurl = 'https://node-nft-prashant.mobiloitte.com';
// let baseurl = 'https://node-smartcontract.mobiloitte.org';
let user = `${baseurl}/api/v1/user`;
let nft = `${baseurl}/api/v1/nft`;

const apiConfig = {
  connectWallet: `${user}/connectWallet`,
  getProfile: `${user}/getProfile`,
  editProfile: `${user}/editProfile`,
  uploadNFT: `${nft}/uploadNFT`,
  addImage: `${nft}/addImage`,
  addNft: `${nft}/addNft`,
  placeOrder: `${nft}/placeOrder`,
  nftWithoutOrderList: `${nft}/nftWithoutOrderList`,
  placeOrderList: `${nft}/placeOrderList`,
  placeOrderListById: `${nft}/placeOrderListById`,
  placeBid: `${nft}/placeBid`,
  sellOrder: `${nft}/sellOrder`,
  orderListParticular: `${nft}/orderListParticular`,
  rejectBid: `${nft}/rejectBid`,
  acceptBid: `${nft}/acceptBid`,
  bidList: `${nft}/bidList`,
};

export default apiConfig;
