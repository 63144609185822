import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";
import { FaGratipay } from "react-icons/fa";

const baseOptions = {
  typography,
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: "#fff",
        border: "1px solid #fff",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        "&:hover": {
          backgroundColor: "#fff",
        },
        "&$focused": {
          backgroundColor: "#fff",
        },
      },
      underline: {
        "&:before": {
          // borderBottomColor: "red"
        },
        "&:hover:not(.Mui-focused):before": {
          // borderBottomColor: "green"
        },
        "&:after": {
          // focused
          // borderBottomColor: "purple"
        },
      },
    },
    MuiTypography: {
      caption: {
        fontSize: "14px",
      },
    },
    MuiSelect: {
      icon: {
        color: "#300760",
      },
    },
    MuiInputBase: {
      root: {
        height: "35px",
        // padding:'7px 8px',
        color: "#300760",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(7px, 12px) scale(1)",
        fontSize: "12px",
        whiteSpace: "pre",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      filled: {
        color: "#fff",
        "&$focused": {
          color: "#fff",
        },
        ".MuiFormControl-root:hover &:not($focused)": {
          color: "#fff",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        fontSize: "14px",
      },
      notchedOutline: {
        borderColor: "rgba(0, 0, 0 , 0.23)",
      },
      multiline: {
        height: "auto",
      },
      color: {
        color: "gray",
      },
    },
    MuiDropzoneArea: {
      root: {
        height: "175px",
        minHeight: "175px",
        marginBottom: "15px",
        borderColor: "#300760",

        backgroundColor: "#fff",
      },
    },
    MuiFormControl: {
      root: {
        // overflow:"hidden",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
        fontWeight: 400,
        fontSize: "16px",
        padding: "2px 25px",
        margin: "10px 0 15px",
      },
      barColorPrimary: {
        backgroundColor: "#5444d5",
      },
      colorPrimary: {
        backgroundColor: "#dedede",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
        color: "white",
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        backgroundColor: "#f5f5f5",
      },
    },
    MuiButton: {
      root: {
        textTransform: "uppercase",
        fontFamily: "'gilroymedium', serif",
        fontSize: "14px",
        letterSpacing: "0.6px",
        height: "48px",
        borderRadius: "10px",
        padding: "5px 20px",
        fontWeight: "400",
        whiteSpace: "pre",
        "@media (max-width: 600px)": {
          height: "35px",
          fontSize: "14px",
        },
      },

      containedPrimary: {
        boxShadow: "none",
        boxShadow: "0 3px 20px rgb(0, 0, 0,0.1)",
        borderRadius: "10px",
        backgroundColor: "transparent",
        transition: "all 500ms",
        height: "48px",
        color: "#300760",
        fontSize: "14px",
        fontWeight: "600",
        minWidth: "150px",
        border: "2px solid #300760",
        "&:hover": {
          color: "#fff",
          boxShadow: "none",
          backgroundColor: "#300760",
          border: "2px solid #300760",
        },
      },
      containedSizeSmall: {
        border: "2px solid #300760",
        borderRadius: "4px",
        backgroundColor: "transparent",
        color: "#300760",
        minWidth: "96px",
        height: "35px",
        "&:hover": {
          boxShadow: "none",
          color: "#fff",
          backgroundColor: "#300760",
          border: "2px solid #300760",
        },
      },
      outlinedSecondary: {
        border: "2px solid #bd3a3a",
        borderRadius: "4px",
        backgroundColor: "transparent",
        color: "#bd3a3a",
        minWidth: "96px",
        height: "35px",
        "&:hover": {
          boxShadow: "none",
          color: "#fff",
          backgroundColor: "#bd3a3a",
          border: "2px solid #bd3a3a",
        },
      },
      containedSecondary: {
        boxShadow: "none",
        boxShadow: "0 3px 20px rgb(0, 0, 0,0.1)",
        borderRadius: "10px",
        backgroundColor: "transparent",
        transition: "all 500ms",
        color: "#300760",
        border: "2px solid #300760",
        "&:hover": {
          boxShadow: "none",
          color: "#fff",
          backgroundColor: "#300760",
          border: "2px solid #300760",
        },
      },
      containedSizeLarge: {
        boxShadow: "none",
        boxShadow: "0 3px 20px rgb(0, 0, 0,0.1)",
        borderRadius: "10px",
        backgroundColor: "#300760",
        transition: "all 500ms",
        whiteSpace: "pre",
        fontSize: "14px",
        color: "#fff",
        fontWeight: "600",
        border: "2px solid #300760",
        "&:hover": {
          boxShadow: "none",
          color: "#300760",
          backgroundColor: "transparent",
          border: "2px solid #300760",
        },
      },

      outlined: {
        boxShadow: "none",
        borderWidth: 2,
      },
      outlinedPrimary: {
        borderRadius: 5,
        color: colors.common.white,
        borderColor: colors.common.white,
        boxShadow: "none",
        fontSize: "14px",
        padding: "10px 59px",
        "&:hover": {
          color: colors.primary,
        },
      },
      outlinedSizeLarge: {
        color: colors.common.white,
        borderColor: colors.common.white,
        borderWidth: 1,

        fontSize: "20px",
        padding: "10px 59px",
        "@media (max-width: 600px)": {
          padding: "5px 25px",
          fontSize: "16px",
        },
      },
    },
    MuiTableCell: {
      head: {
        color: "#222",
      },
      stickyHeader: {
        backgroundColor: "#dcdcdc",
        fontWeight: "600",
      },
    },
    MuiMenuItem: {
      root: {
        color: "white",
      },
    },
    MuiPaper: {
      root: {
        color: "#cb0267",
        backgroundColor: "#09080d",
      },
      outlined: {
        backgroundColor: "#f5f5f5",
        padding: "10px",
        borderRadius: "0",
        border: "0",
      },
    },
    MuiLink: {
      underlineHover: {
        "&:hover": {
          textDecoration: "none",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#300760",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#300760",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#300760",
      secondary: "#300760",
    },
    common: {
      black: "#222222",
    },
  },
  BreedDetails: {
    MuiInputBaseInput: {
      color: "gray",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
