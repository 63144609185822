import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
   
  makeStyles,
} from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom';
import { VscBrowser } from "react-icons/vsc";
import { useWeb3React } from '@web3-react/core';
import {} from "react-feather";
import { BorderBottom } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  headerSection: {
    background: "linear-gradient(270deg, #f20066 -0.01%, #500669 105.12%)",
    boxShadow: "6.79395px 6.79395px 30.1953px rgba(24, 24, 24, 0.18)",
    top: "0",
    position: "relative",
    width: "100%",
    left: "0",
    zIndex: "9",
    maxWidth: "100%",
    overflow: "auto",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },

  iconText: {
    width: 14,
    height: 14,
    marginRight: "8px",
    color: "#fff",
  },
  iconBox: {
    display: "flex",
  },
}));

export default function Header() {
  const classes = useStyles();
  const { account } = useWeb3React();
  return (
    <>
    <Box className={classes.headerSection}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
          
              <Box className="secondaryHeader" style={{display:"flex", justifyContent:"start"}}>
                <RouterLink to="/marketplace">
                  <Box className={classes.iconBox}>
            <VscBrowser className={classes.iconText}/>
               <Typography variant="body2" className="iconText">MarketPlace</Typography>
              </Box>
              </RouterLink>
              {account && (
              <RouterLink to="/my-nft">
                <Box className={classes.iconBox}>
                <VscBrowser className={classes.iconText}/>
                <Typography variant="body2" className="iconText">My NFT</Typography>
              </Box>
              </RouterLink>
               )}
              </Box>
            
           </Grid>
        </Container>
      </Box>
    </>
  );
}
